<template>
    <div class="h-full">
        <div :class="config.isDetailDisplay ? 'small-list' : 'scroll-auto'">
            <Control ref="control" :config="config" @onChangeEditDataSource="onChangeEditDataSource"></Control>
        </div>
        <div v-show="config.isDetailDisplay" class="detail">
            <EditControl ref="classifyEdit" :dataSource="editDataSource" :config="config"></EditControl>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            config: {
                isDetailDisplay: false,
            },
            editDataSource: {},
            initialDataSource: {},
        };
    },
    methods: {
        onChangeEditDataSource: function (dataSource) {
            this.editDataSource = dataSource;
            this.initialDataSource = this.Utils.extend({}, dataSource);
            this.$refs.classifyEdit.productCategoryTreeFn();
        },
        syncDataSource(dataSource) {
            this.editDataSource = this.Utils.extend({}, dataSource);
            this.$refs.classifyEdit.productCategoryTreeFn();
        },
        checkSaveStatus() {
            this.config.isDetailDisplay = false;
        }
    },
    components: {
        "EditControl": resolve => { require(['./components/edit.vue'], resolve) },
        "Control": resolve => { require(['./components/list.vue'], resolve) },
    },
};
</script>